<template>
  <v-container fluid>
    <div class="row ma-0 pa-0">
      <v-speed-dial
        v-model="fab"
        :top="top"
        :bottom="bottom"
        :right="right"
        :left="left"
        :direction="direction"
        :open-on-hover="hover"
        :transition="transition"
      >
        <template v-slot:activator>
          <v-btn v-model="fab" color="blue darken-2" dark fab>
            <v-icon v-if="fab"> mdi-close </v-icon>
            <v-icon v-else> mdi-plus </v-icon>
          </v-btn>
        </template>
        <v-btn fab dark small color="red" @click="imprimir(false)">
          <v-icon>mdi-printer</v-icon>
        </v-btn>
        <v-btn
          fab
          dark
          small
          color="green"
          @click="imprimir(true)"
          v-if="tipo != 'nuevo'"
        >
          <v-icon dark> mdi-cloud-upload </v-icon>
        </v-btn>
        <!-- <v-btn fab dark small color="indigo">
          <v-icon>mdi-plus</v-icon>
        </v-btn> -->
      </v-speed-dial>
      <div class="col-12 mb-0 pa-0">
        <center>
          <h3>INSTRUCTIVO COMPRA {{ instructivo[0].expediente }}</h3>

          <h4 class="mb-1 ml-1">
            {{ instructivo[0].sentido }} - {{ instructivo[0].carga }} -
            {{ instructivo[0].incoterms }}
          </h4>
        </center>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-4">
        <div class="col-12">
          <v-card elevation="5" class="pa-1">
            <h4 class="mb-5 ml-1">DATOS DEL CLIENTE</h4>
            <v-text-field
              class="my-1"
              readonly
              v-model="instructivo[0].nombre"
              label="NOMBRE:"
              dense
            ></v-text-field>
            <v-text-field
              class="my-1"
              readonly
              v-model="instructivo[0].direccion"
              label="DIRECCIÓN:"
              dense
            ></v-text-field>
            <v-text-field
              class="my-1"
              readonly
              v-model="instructivo[0].contacto"
              label="CONTACTO:"
              dense
            ></v-text-field>

            <v-text-field
              class="my-1"
              readonly
              v-model="instructivo[0].vendedor"
              label="VENDEDOR:"
              dense
            ></v-text-field>
          </v-card>
        </div>
        <div class="col-12">
          <v-card class="pa-1" elevation="5">
            <h4 class="mb-1 ml-1">SERVICIOS A REALIZAR</h4>
            <v-simple-table dense>
              <tbody>
                <tr v-for="(servicio, i) in servicios" :key="i">
                  <td>{{ servicio.descripcion }}</td>
                  <td>{{ servicio.status == 1 ? "SI" : "NO" }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </div>
        <div class="col-12">
          <v-card class="pa-1" elevation="5">
            <h4 class="mb-5 ml-1">DATOS DE MOVIMIENTO DE MERCANCIA</h4>
            <v-text-field
              class="my-1"
              dense
              label="NOMBRE DEL PROVEEDOR:"
              v-model="instructivo[0].proveedor"
              required
            >
            </v-text-field>
            <v-text-field
              class="my-1"
              dense
              readonly
              v-model="instructivo[0].direccionorigen"
              label="DIRECCION ORIGEN:"
              v-if="instructivo[0].icotems == 'SXWORK'"
            >
            </v-text-field>
            <v-text-field
              class="my-1"
              dense
              readonly
              v-model="instructivo[0].origen"
              label="POL:"
            >
            </v-text-field>
            <v-text-field
              class="my-1"
              dense
              readonly
              v-model="instructivo[0].destino"
              label="POD:"
            >
            </v-text-field>
            <v-text-field
              class="my-1"
              dense
              v-model="instructivo[0].fiscal"
              required
              label="NOMBRE FISCAL"
            >
            </v-text-field>
            <v-text-field
              class="my-1"
              dense
              readonly
              v-model="instructivo[0].ruc"
              label="RUC:"
            >
            </v-text-field>
          </v-card>
        </div>
        <div class="col-12">
          <v-card class="pa-1" elevation="5">
            <h4 class="mb-5 ml-1">
              NOTAS ESPECIALES:
              <v-btn icon color="primary" @click="dialog = true" class="mx-2">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </h4>
            <v-simple-table dense border>
              <tbody>
                <tr v-for="(nota, i) in Notas" :key="i">
                  <td>{{ nota.description }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </div>
        <div class="col-12">
          <v-flex text-right>
            <v-btn color="success" readonly small @click="actualizar"
              >Guardar</v-btn
            >
          </v-flex>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-8">
        <div class="col-12">
          <v-card class="pa-1" elevation="5">
            <h4 class="mb-1 ml-1">INGRESOS</h4>
            <v-simple-table dense>
              <thead>
                <tr>
                  <th>DESCRIPCIÓN</th>
                  <th>MONTO</th>
                  <th>IGV</th>
                  <th>TOTAL</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(venta, i) in ventas"
                  :key="i"
                  v-bind:class="
                    venta.descripcion == 'Total' ? 'totalClass' : ''
                  "
                >
                  <td :rowspan="venta.row" v-if="venta.row">
                    {{ venta.proveedor }}
                  </td>
                  <td>
                    {{ venta.descripcion }}
                  </td>
                  <td class="derecha">
                    {{ currencyFormat(venta.valor) }}
                  </td>
                  <td class="derecha">
                    {{ currencyFormat(venta.igv) }}
                  </td>
                  <td class="derecha">
                    {{
                      currencyFormat(
                        parseFloat(venta.valor) + parseFloat(venta.igv)
                      )
                    }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </div>
        <div class="col-12">
          <v-card class="pa-1" elevation="5">
            <h4 class="mb-1 ml-1">COSTOS:</h4>

            <v-simple-table dense>
              <thead>
                <tr>
                  <th>PROVEEDOR</th>
                  <th>CONCEPTO</th>
                  <th>MONTO</th>
                  <th>IGV</th>
                  <th>TOTAL</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(costo, i) in costos"
                  :key="i"
                  v-bind:class="costo.proveedor == 'Total' ? 'totalClass' : ''"
                >
                  <td :rowspan="costo.row" v-if="costo.row">
                    {{ costo.proveedor }}
                  </td>
                  <td>
                    {{ costo.descripcion }}
                  </td>
                  <td class="derecha">
                    {{ currencyFormat(costo.valor) }}
                  </td>
                  <td class="derecha">
                    {{ currencyFormat(costo.igv) }}
                  </td>
                  <td class="derecha">
                    {{
                      currencyFormat(
                        parseFloat(costo.valor) + parseFloat(costo.igv)
                      )
                    }}
                  </td>
                </tr>
                <tr>
                  <th colspan="2">TOTAL:</th>
                  <th class="derecha">{{ currencyFormat(total.monto) }}</th>
                  <th class="derecha">{{ currencyFormat(total.igv) }}</th>
                  <th class="derecha">
                    {{ currencyFormat(total.monto + total.igv) }}
                  </th>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </div>
      </div>
    </div>
    <!-- MODAL NOTAS -->
    <v-dialog v-model="dialog" max-width="600px" transition="dialog-transition">
      <v-card>
        <v-card-title primary-title> NUEVA NOTA: </v-card-title>
        <v-card-text>
          <v-form ref="nota">
            <v-text-field
              label="DESCRIPCIÓN"
              v-model="descripcionNota"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="success" text @click="guardarNota">Añadir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogInstructivo"
      scrollable
      persistent
      :overlay="false"
      max-width="600px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title> Generado PDF... </v-card-title>

        <v-card-text>
          <v-progress-linear
            color="red darken-4"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- subir archivo -->
    <v-dialog v-model="dialogSubir" hide-overlay persistent width="400">
      <v-card color="text-h3">
        <v-card-text>
          Subiendo El archivo generado <br />
          <v-progress-circular
            :rotate="180"
            :size="100"
            :width="15"
            :value="value"
            color="red"
          >
            {{ value }}
          </v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import funcion from "@/mixins/funciones";
import axios from "axios";
export default {
  mixins: [funcion],
  props: ["tipo"],
  data() {
    return {
      headersIngreso: [
        { text: "DESCRIPCIÓN", align: "start", value: "descripcion" },
        { text: "MONTO", align: "start", value: "valor" },
        { text: "IGV", align: "start", value: "igv" },
        { text: "TOTAL", align: "start", value: "total" },
      ],
      total: { monto: 0, igv: 0, total: 0 },
      dialog: false,
      dialogSubir: false,
      value: 0,
      descripcionNota: "",
      dialogInstructivo: false,
      ventas: [],
      Notas: [],
      costos: [],
      servicios: [],
      fromData: {},
      // SSSS
      direction: "right",
      fab: false,
      fling: true,
      hover: false,
      tabs: null,
      top: true,
      right: true,
      bottom: false,
      left: true,
      transition: "slide-y-reverse-transition",
    };
  },

  mounted() {},
  computed: {
    ...mapState([
      "instructivo",
      "datosPrincipales",
      "totalVenta",
      "totalOpcion",
    ]),
  },
  methods: {
    ...mapActions(["getFilesQuote"]),
    ...mapMutations(["setActualizarListaArchivos"]),
    guardarNota() {
      if (this.$refs.nota.validate()) {
        this.Notas.push({
          description: this.descripcionNota,
          id_quote: this.instructivo[0].id,
        });
        this.$refs.nota.reset();
        this.dialog = false;
      }
    },
    guardarDatos() {
      for (let i = 0; i < this.instructivo.length; i++) {
        if (this.instructivo[i].ventas) {
          let totalVentas = {
            valor: 0.0,
            igv: 0.0,
          };
          this.ventas = this.instructivo[i].ventas;

          this.ventas.forEach((element) => {
            totalVentas.valor += parseFloat(element.valor);
            totalVentas.igv += parseFloat(element.igv);
          });
          this.ventas.push({
            descripcion: "Total",
            valor: totalVentas.valor,
            igv: totalVentas.igv,
          });
        }
        if (this.instructivo[i].Notas) {
          this.Notas = this.instructivo[i].notas;
        }
        if (this.instructivo[i].costos) {
          this.generarDatosCostos(
            this.instructivo[i].costos.sort((a, b) => {
              if (a.proveedor < b.proveedor) return -1;
              if (a.proveedor > b.proveedor) return 1;
            })
          );
          // this.costos = this.instructivo[i].costos;
        }
        if (this.instructivo[i].servicios) {
          this.servicios = this.instructivo[i].servicios;
        }
      }
      this.costos.forEach((element) => {
        this.total.monto += parseFloat(element.valor);
        this.total.igv += parseFloat(element.igv);
        this.total.total += parseFloat(element.valor + element.igv);
      });
    },
    async actualizar() {
      let data = {
        proveedor: this.instructivo[0].proveedor,
        fiscal: this.instructivo[0].fiscal,
        direccionorigen: this.instructivo[0].direccion,
        notas: this.Notas,
      };

      await this.obtenerDataApi(
        "post",
        process.env.VUE_APP_URL_MAIN + "putInstructivo/" + this.instructivo[0].id,
        data
      );
    },

    generarDatosCostos(datos) {
      var proveedor = "";
      var nuevoProvedor = "";
      var cost = [];
      var nuevoFlag = false;
      // var monto = 0;
      // var igv = 0;
      // var totalFlag = false;
      datos.forEach((element) => {
        if (element.proveedor != proveedor) {
          proveedor = element.proveedor;
        }

        if (!nuevoProvedor) {
          nuevoProvedor = element.proveedor;
          nuevoFlag = true;
        } else if (nuevoProvedor === element.proveedor) {
          nuevoFlag = false;
        } else {
          nuevoProvedor = element.proveedor;
          nuevoFlag = true;
        }

        const row = this.calcularRow(datos, proveedor);
        cost.push({
          proveedor: nuevoFlag ? nuevoProvedor : "",
          row: nuevoFlag ? row : "",
          descripcion: element.descripcion,
          valor: element.valor,
          igv: element.igv,
        });
      });
      this.costos = cost;
    },
    calcularRow(datos, proveedor) {
      var cantidad = datos.filter((v) => v.proveedor == proveedor);
      return cantidad.length;
    },

    async imprimir(subir = false) {
      this.dialogInstructivo = !subir;
      this.dialogSubir = subir;
      if (subir) {
        this.aumentarCarga(1);
      }
      let config = {
        method: "post",
        url: process.env.VUE_APP_URL_MAIN + `getInstructivoId/${this.datosPrincipales.id}`,
        headers: {
          "auth-token": JSON.parse(localStorage.getItem("token")),
          "Content-Type": "application/json",
        },
      };

      await axios(config).then((response) => {
        console.table(response.data.data)
        this.generar(response.data.data, subir);
      });
    },
    async generar(datos, subir = false) {
      let headers = null;
      if (!subir) {
        headers = {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Accept: "aplication/json",
          "Access-Control-Allow-Methods":
            "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers":
            "Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length",
          responseType: "arraybuffer",
        };
      } else {
        headers = {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Accept: "aplication/json",
          "Access-Control-Allow-Methods":
            "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers":
            "Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length",
        };
      }

      datos[0].subirflag = subir;
      datos[0].ganancia =
        this.totalVenta.flete -
        this.totalOpcion.flete +
        this.totalVenta.gasto -
        this.totalOpcion.gasto +
        this.totalVenta.aduana -
        this.totalOpcion.aduana +
        this.totalVenta.almacen -
        this.totalOpcion.almacen;
      axios
        .post(
          process.env.VUE_APP_URL_REPORT+"quote_instructivo",
          datos,
          headers
        )
        .then((response) => {
          if (!subir) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;

            let name = this.uuidv4();
            link.setAttribute("download", name + ".pdf");
            document.body.appendChild(link);
            link.click();
            this.dialogInstructivo = false;
          } else {
            this.guardarRutaPreview(response.data);
          }

          // this.previewFlag = false;
          // this.generandoFlag = false;
        });
    },
    async guardarRutaPreview(res) {
      let datos = {
        id_quote: this.$route.params.id,
        name: `Instructivo N° : ${this.datosPrincipales.quote}`,
        type: "pdf",
        path: res.path,
        status: 1,
      };

      let config = {
        method: "post",
        url: process.env.VUE_APP_URL_MAIN + "setPath",
        headers: {
          "auth-token": JSON.parse(localStorage.getItem("token")),
          "Content-Type": "application/json",
        },
        data: datos,
      };
      await axios(config).then((response) => {
        if (response.data.statusBol == true) {
          this.value = 100;
          this.getFilesQuote(this.$route.params.id);
          this.setActualizarListaArchivos();
          setTimeout(() => {
            this.dialogSubir = false;
          }, 2000);
        }
      });
    },
    uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      );
    },
    aumentarCarga(valor) {
      this.value = valor;
      if (valor < 99) {
        setTimeout(() => {
          this.aumentarCarga(valor + 1);
        }, 5);
      }
    },
  },

  beforeMount() {
    this.guardarDatos();
  },
  watch: {
    top(val) {
      this.bottom = !val;
    },
    right(val) {
      this.left = !val;
    },
    bottom(val) {
      this.top = !val;
    },
    left(val) {
      this.right = !val;
    },
  },
};
</script>

<style scoped>
.derecha {
  text-align: right !important;
}

.totalClass {
  font-weight: bold;
  background: whitesmoke;
}

.flotante {
  position: fixed !important;
  z-index: 99;
  /* bottom: 40px;
	right: 40px; */
}
#create .v-speed-dial {
  position: absolute;
}

#create .v-btn--floating {
  position: relative;
}
</style>
